import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlphaFeature } from 'hooks/use-alpha-feature';
import { DC_TEAM_TEST_TENANTS } from 'utils/constants/tenant-ids';
import { useCustomViews } from 'hooks/use-custom-views';
import { LoadingButton } from 'components/loaders/loading-button';
import { useLocation } from 'react-router-dom';
import { SaveCustomViewDialog } from 'components/custom-view/save-custom-view-dialog';
import { isPredefinedView } from 'utils/helpers/url-utils/url-params-manager';

const CustomViewOptions = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [isDialogOpened, setDialogOpened] = useState(false);

  const { isAlphaFeature: isCustomViewsEnabled } = useAlphaFeature(
    DC_TEAM_TEST_TENANTS,
    'https://hawkai.atlassian.net/browse/DC-1278',
    new Date(2024, 10, 31),
  );
  const { isLoading } = useCustomViews(isCustomViewsEnabled);

  const isSaveViewBtnRendered = isCustomViewsEnabled && !isLoading && !isPredefinedView(search);

  return (
    <>
      {isSaveViewBtnRendered && (
        <>
          <Box component="span" className="pr-2">
            <LoadingButton
              title={t('case:caseList.customViews.saveView.heading')}
              size="medium"
              variant="text"
              onClick={() => setDialogOpened(true)}
              isLoading={isLoading}
              globalActionListener
            />
          </Box>
          <SaveCustomViewDialog isDialogOpened={isDialogOpened} setDialogOpened={setDialogOpened} />
        </>
      )}
    </>
  );
};

export default CustomViewOptions;
