const DETACHMENT_CONFIG = {
  customerScreeningStandalone: 'customerScreeningStandalone',
  customerCasesList: 'customerRiskRatingCasesList',
  customerView: 'customerView',
  customerManualScoringChange: 'customerManualScoringChange',
  customerRiskRating: {
    individual: 'customerRiskRatingForIndividual',
    organization: 'customerRiskRating',
  },
  workspace: 'workspace',
  caseQualityAssurance: 'caseQualityAssurance',
  dashboard: {
    efficiencyDashboard: 'efficiencyDashboard',
    caseStatisticDashboard: 'caseStatisticDashboard',
    truePositiveDashboard: 'truePositiveDashboard',
    customerStatisticsDashboard: 'customerStatisticsDashboard',
    platformPerformanceDashboard: 'platformPerformanceDashboard',
  },
  configurations: {
    settings: 'settings',
    ruleManager: 'ruleManager',
    listManager: 'listManager',
    dataProviderList: 'dataProviderList',
    entityResolution: 'entityResolution',
    machineIntelligence: 'machineIntelligence',
    dataFeeds: 'dataFeeds',
    tenantConfig: 'tenantConfiguration',
    mccList: 'mccList',
    goAmlReport: 'goAmlReport',
    finCenReport: 'finCenReport',
    whitelabel: 'whitelabel',
    regulatoryChecks: 'regulatoryChecks',
    categoryWorkflows: 'categoryWorkflows',
    caseNotifications: 'caseNotifications',
    sftp: 'sftp',
  },
  rules: {
    accountBasedFrequency: 'accountBasedFrequencyRules',
    accountBasedFrequencyIncreaseDecrease: 'accountBasedFrequencyRules',
    accountBlacklist: 'accountBlacklistConfig',
    accountHoldingPartyDataReference: 'accountHoldingPartyDataReference',
    adverseMedia: 'adverseMedia',
    bankFalsePositiveFilter: 'bankFalsePositiveFilter',
    blacklist: 'blacklist',
    cashTransaction: 'cashVolumeRiskConfig',
    counterPartyFrequency: 'frequencyRules',
    counterPartyVolume: 'counterpartyVolume',
    countryRisk: 'countryRules',
    countryRiskV2: 'countryRulesV2',
    countryRules: 'countryRules',
    countryRulesV2: 'countryRulesV2',
    crossBorderVolume: 'crossBorderVolumeRules',
    customerScreeningStandalone: 'customerScreeningStandalone',
    dormantAccount: 'dormantAccountActivationRules',
    falsePositivePatternLegitimateReferenceAccountMismatch:
      'falsePositivePatternLegitimateReferenceAccountMismatch',
    falsePositivePatternSendMoneyHome: 'falsePositivePatternSendMoneyHome',
    instanciablePepSanction: 'instanciablePepSanction',
    passthrough: 'passthroughRules',
    passthroughV2: 'passthroughRulesV2',
    passthroughV3: 'passthroughRulesV3',
    pepSanction: 'sanctionAndPepRules',
    plausibility: 'plausibility',
    referenceAccountMismatch: 'referenceAccountMismatch',
    referenceAccountV2: 'referenceAccountV2',
    riskyWords: 'riskyWordsRules',
    roundAmount: 'roundAmountRules',
    roundAmountRatioIncreaseDecrease: 'roundAmountRatioIncreaseDecreaseRules',
    smurfing: 'smurfingRules',
    suspiciousAvgTicketSize: 'suspiciousAvgTicketSizeRules',
    suspiciousPatternMoneyMule: 'suspiciousPatternMoneyMule',
    suspiciousPatternShellCompany: 'suspiciousPatternShellCompany',
    volumeIncreaseDecrease: 'volumeIncreaseDecreaseRules',
    volumeRisk: 'volumeRules',
    volumeRiskV2: 'volumeRulesV2',
    volumeRules: 'volumeRules',
    volumeRulesV2: 'volumeRules',
    riskyWordsV2: 'riskyWordsRulesV2',
    expectedCustomerValue: 'expectedCustomerValue',
    amlRiskScreening: 'amlRiskScreening',
    flagging: 'flaggingRules',
    walletScreening: 'walletScreening',
    GenericRuleDecision: 'GenericRuleDecision',
    PercentageRuleDecision: 'PercentageRuleDecision',
    FrequentChangesRuleDecision: 'FrequentChangesRuleDecision',
    GroupingRuleDecision: 'GroupingRuleDecision',
    VolumeRiskV3Decision: 'VolumeRiskV3Decision',
    FrequencyV2Decision: 'FrequencyV2Decision',
    genericRuleDetachmentMap: {
      GenericRuleDecision: 'GenericRuleDecision',
      percentage_rule: 'percentage_rule',
      FrequentChangesRuleDecision: 'FrequentChangesRuleDecision',
      PercentageRuleDecision: 'PercentageRuleDecision',
      GroupingRuleDecision: 'GroupingRuleDecision',
      VolumeRiskV3Decision: 'VolumeRiskV3Decision',
      FrequencyV2Decision: 'FrequencyV2Decision',
    },
  },
  dualControl: {
    ruleConfigChange: 'dualControlRuleConfigChange',
  },
  genericRuleDetachmentMap: {
    GenericRuleDecision: 'GenericRuleDecision',
    percentage_rule: 'percentage_rule',
    RomanRuleCount: 'RomanRuleCount',
    FrequentChangesRuleDecision: 'FrequentChangesRuleDecision',
    PercentageRuleDecision: 'PercentageRuleDecision',
    GroupingRuleDecision: 'GroupingRuleDecision',
    VolumeRiskV3Decision: 'VolumeRiskV3Decision',
    FrequencyV2Decision: 'FrequencyV2Decision',
  },
  machineIntelligence: {
    machineIntelligence: 'machineIntelligence',
    bankFalsePositiveModel: 'bankFalsePositiveModel',
    behavioralAnalysis: 'behavioralAnalysis',
  },
  orderDetailsView: 'orderDetailsView',
  blockingRules: 'blockingRules',
  experimentation: 'experimentationEnvironment',
  configPropagation: 'configPropagation',
  adhocScreening: 'adhocScreening',
  languageSelector: 'languageSelector',
};

export { DETACHMENT_CONFIG };
