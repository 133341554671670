import crossBorderVolumeIcon from 'assets/images/icon-cross-border-volume.png';
import suspiciousAvgTicketSizeIcon from 'assets/images/icon-suspicious-avg-ticket-size.png';
import volumeIncreaseDecreaseIcon from 'assets/images/icon-volume-increase-decrease.png';
import AssessmentAnswer from 'utils/constants/assessment-answer';
import { ComputeSegment } from 'utils/constants/cross-border-time-segment';
import DataFeatureName from 'utils/constants/data-feature-name';
import DecisionName from 'utils/constants/decision-name';
// import PreparationStep from 'constants/preparation-step';
import TransactionType from 'utils/constants/transaction-type';

/**
 * DEPRECATED please don't add new strings or keys in this object
 * either add it to a constant.js or create a new constant file if its a JS key
 * or if its a language in the corresponding .json file
 */

const Strings = {
  Sidebar: {
    MENU: 'CASES',
    CONFIGURATIONS: 'CONFIGURATIONS',
    AUDIT: 'AUDIT',
    'HEALTH AND PERFORMANCE': 'HEALTH & PERFORMANCE',
    Dashboard: 'Dashboard',
    'My Cases': 'My cases',
    'Escalated Cases': 'Escalated cases',
    'Automatically Closed Review Cases': 'Auto-closed review',
    'Open Cases': 'Open cases',
    'Priority Cases': 'Priority cases',
    'Sanction Cases': 'Sanction cases',
    'AML Cases': 'AML cases',
    'Fraud Cases': 'Fraud cases',
    'Automatically Closed Cases': 'Auto-closed cases',
    'Not Opened Cases': 'Not opened cases',
    'Rule Manager': 'Rule manager',
    'Closed Cases': 'Closed cases',
    'Cases Being Processed': 'Cases being processed ...',
    Search: 'Search',
    'Search cases': 'Search cases',
  },

  CaseTable: {
    'Open Cases': 'Open Cases',
    'Back to': 'Back to',
    'Back to Open Cases': 'Back to Open Cases',
    Today: 'Today',

    TYPE: 'Type',
    PRIORITY: 'PRIORITY',
    'CASE STATUS': 'Case status',
    'TRANSACTION STATUS': 'Transaction status',
    'PROCESSING STATUS': 'Processing status',
    CUSTOMER: 'Customer',
    'TRANSACTION COUNT': 'TX count',
    'FUNDS TOTAL': 'Funds total',
    FLOW: 'Flow',
    RECOMMENDATION: 'RECOMMENDATION',
    COUNT: 'COUNT',
    PERIOD: 'PERIOD',
    TIMESTAMP: 'Last updated',
    'ASSIGNED TO': 'Assigned to',
    ACTIONS: 'Actions',

    '1-click-option': '1-click-option',

    'All cases accurately processed': 'All cases accurately processed',
    'Please select filter to search': 'Please select filter to search',

    Debitor: 'Debitor',
    Name: 'Name',
    Creditor: 'Creditor',
    'Customer since': 'Customer since',
    Product: 'Product',
    'Last KYC Update': 'Last KYC update',

    Transaction: 'Transaction',
    Usage: 'Usage',
    'Previous Transactions (aggregated)': 'Previous transactions (aggregated)',
    Crypto: 'Crypto',

    'Relevant Similarity in Client Crypto History': 'Relevant similarity in client crypto history',
    'Limit & Crypto': 'Limit & crypto',
    Agent: 'Agent',
    'called customer': 'called customer',
    'Case closed plausible': 'Case closed plausible',

    'More details': 'More details',
    Approve: 'Approve',

    'Case has been successfully closed': 'Case has been successfully closed',

    'Sanction hit comparison': 'Sanction hit comparison',
    'Company structure comparison': 'Company structure comparison',
    Transactions: 'Transactions',
    'Transaction history': 'Transaction history',

    'Sanctioned Company': 'Sanctioned company',
    Address: 'Address',
    Web: 'Web',

    Reasons: 'Reasons',
    'SANCTION LIST': 'SANCTION LIST',
    'Sanction Hit': 'Sanction hit',
    Suspicion: 'Suspicion',

    relevant: 'Relevant',
    impact: 'Impact',

    'Split cases': 'Split cases',
    'Files SAR': 'Files SAR',
    'Mark all false positive': 'Mark all false positive',
    transaction: 'transaction',
    transactions: 'transactions',
    Confidence: 'Confidence',

    'Blocked transaction(s)': 'Blocked transaction(s)',
    'Transaction(s)': 'Transaction(s)',
    Amount: 'Amount',
    Currency: 'Currency',
    Category: 'Category',
    Debitors: 'Debitors',
    debitors: 'debitors',
    Customer: 'Customer',
    Initiator: 'Initiator',
    'Counter party': 'Counter party',
    'Cash Transaction': 'Cash Transaction',
    Country: 'Country',
    'Debitor ID': 'Debitor ID',
    min: 'min',
    max: 'max',
    avg: 'avg',
    'low risk': 'low risk',
    'medium risk': 'medium risk',
    'Funds originator': 'Originator',
    'Funds beneficiary': 'Beneficiary',
    'Settled funds': 'Settled funds',
    'Initiated funds': 'Initiated funds',
    'Settled funds in base currency': 'Settled funds in base currency',
    Funds: 'Funds',
    'Purpose code': 'Purpose code',
    TenantTransactionID: 'Tenant transaction ID',

    Escalate: 'Escalate',
    'File SAR': 'File SAR',
    'Highly unsual Money Flow for Type of Business!':
      'Highly unsual money flow for type of business!',
    Type: 'Type',
    Company: 'Company',
    Industry: 'Industry',
    'Managing Director': 'Managing director',
    'Case history': 'Case history',
    Similarity: 'Similarity',
    ShiftTooltip: 'Hold shift to sort by multiple columns',
    'Customer Details': 'Customer Details',
    Blacklisted: 'blacklisted',
    Unassigned: 'Unassigned',
    TenantProcessingTimestamp: 'Tenant processing timestamp',
  },

  Modal: {
    Close: 'Close',
    Confirm: 'Confirm',
  },

  Button: {
    UPLOAD: 'Upload',
    ASSIGN: 'Assign',
  },

  ProcessingStatus: {
    UNDEFINED: 'Undefined',
    NEW: 'New',
    UNSUSPICIOUS: 'Unsuspicious',
    MACHINE_REVIEW: 'Machine review',
    RELIEVED: 'Relieved',
    OPERATOR_REVIEW: 'Operator review',
    ACCEPTED: 'Accepted',
    ESCALATED: 'Escalated',
    SAR_FILING: 'SAR filing',
    SAR_FILED: 'SAR filed',
    REOPENING: 'Reopening',
    REOPENED: 'Reopened',
  },

  CaseStatus: {
    OPENING: 'Opening',
    NOT_OPENED: 'Not opened',
    COMPUTING: 'Computing',
    OPEN: 'Open',
    CLOSED: 'Closed',
    AUTO_CLOSED: 'Auto closed',
  },

  NameType: {
    PRIMARY_NAME_PERSON: 'Primary name',
    PRIMARY_NAME_ENTITY: 'Primary name',
    AKA_PERSON: 'Also known as',
    AKA_ENTITY: 'Also known as',
    SPELLING_VARIATION_PERSON: 'Spelling variation',
    LOW_QUALITY_AKA_PERSON: 'Low quality',
    EXPANDED_LANGUAGE_VARIATION_PERSON: 'Expanded variation',
    UNKNOWN: 'Unknown',
  },

  ErrorMessage: {
    AUTHENTICATION_FAILED:
      'Login failed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',
    PASSWORD_EXPIRED:
      'Your password is expired.<br />Please click Login again and change your password.',

    NO_TENANT_ID:
      'Welcome to the Case Manager.<br />Please ask a supervisor to assign you to a tenant.',

    CASE_DETAIL_DATA_NOT_FOUND: 'Case not found:<br />{0}',

    CASE_MANAGER_BACKEND_NOT_AVAILABLE:
      'Case Manager backend is not available: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',
    TENANT_CASE_FILES_NOT_AVAILABLE:
      'Files Manager backend is not available: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',
    SECURE_STORAGE_NOT_AVAILABLE:
      'Secure storage is not available: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',
    SANCTION_BACKEND_NOT_AVAILABLE:
      'Sanction backend is not available: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',

    CASES_DATA_INVALID:
      'Cases data is invalid: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',
    CASE_DETAIL_DATA_INVALID:
      'Case detail data is invalid: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',
    SANCTION_HITS_DATA_INVALID:
      'Sanction hits data is invalid: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',
    SECURE_STORAGE_DATA_INVALID:
      'Secure storage data is invalid: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',

    CASE_NOTE_DATA_INVALID: 'The userId and note cannot be empty.',
    DECISION_ENGINE_DATA_INVALID: 'Decision engine configuraion data is invalid.',

    ACCOUNT_BLACKLIST_LIST_DATA_INVALID: 'Account Blacklist data is invalid.',
    SUSPICIOUS_WORDS_DATA_INVALID: 'Suspicious words data is invalid.',

    AUTH0_API_NOT_AVAILABLE: 'Auth0 service is not available.',
    AUTH0_API_ERROR: 'Auth0 service operation failed.',

    DETACHMENT_CONFIG_MISSING: 'Detachment service is not available.',
    DETACHMENT_CONFIG_FAILED_UPDATE: 'Detachment service is not available.',

    PASSTHROUGH_CONFIG_DATA_INVALID:
      'Passthrough config data is invalid: requested information cannot be displayed. Please try again in a couple of minutes.<br />Contact support if the problem persists.',
    CONFLUENCE_PAGE_NOT_AVAILABLE: 'The Confluence page for description is not available.',
    CONFLUENCE_DATA_INVALID: 'The Confluence data is invalid.',

    MCC_LIST_DATA_NOT_FOUND: 'MCC list configuration not found',

    AUDIT_TRAIL_ADD_PAGE_INVALID:
      'Audit trail rest service is not responding correctly. Please try again in a couple of minutes.<br />Contact support if the problem persists.',

    CUSTOMER_RISK_RATING_CONFIG_UPDATE_DATA_INVALID:
      'Customer risk rating configuration could not be updated. Please check for invalid inputs.',

    RESET_CONTENT: 'Page expired, please click "Refresh" to reload page',

    FINCEN_SAR_DATA_INVALID: 'FinCen e-SAR filing data is invalid. Please check the inputs.',

    LIST_PROVIDERS_MISMATCH:
      'List providers differ in source and target tenants. Config propagation aborted.',
  },

  CaseFilterColumns: {
    CASE_ID: 'Case ID',
    TYPE: 'Type',
    'TRANSACTION STATUS': 'Transaction status',
    'PROCESSING STATUS': 'Processing status',
    CUSTOMER: 'Customer',
    FUNDS_TOTAL: 'Funds total',
    RECOMMENDATION: 'Recommendation',
    FLOW: 'Flow',
    TIMESTAMP: 'Last updated',
    ASSIGNED_TO: 'Assigned to',
    TENANT_TRANSACTION_ID: 'Tenant transaction ID',
    ACCOUNT_ID: 'Account ID',
    ACCOUNT_NUMBER: 'Account number',
    CUSTOMER_ID: 'Customer ID',
    CUSTOMER_GROUP_ID: 'Customer group ID',
  },

  TransactionStatus: {
    UNDEFINED: 'Undefined',
    PROCEEDED: 'Proceeded',
    BLOCKED: 'Blocked',
    UNBLOCKED: 'Unblocked',
  },

  CaseFilterTitles: {
    ANY: '<any>',
    'FILTER BY': 'FILTER BY',
    'FILTER VALUE': 'FILTER VALUE',
    CURRENCY: 'CURRENCY',
    'FILTER OPERATOR': 'FILTER OPERATOR',
    'CUSTOMER NAME': 'CUSTOMER NAME',
    'FUNDS TOTAL AMOUNT': 'FUNDS TOTAL AMOUNT',
    FROM: 'FROM',
    TO: 'TO',
    DATE: 'DATE',
    CASE_ID: 'CASE ID',
    TENANT_TRANSACTION_ID: 'TENANT TRANSACTION ID',
    ACCOUNT_ID: 'ACCOUNT ID',
    ACCOUNT_NUMBER: 'ACCOUNT NUMBER',
    CUSTOMER_ID: 'CUSTOMER ID',
    CUSTOMER_GROUP_ID: 'CUSTOMER GROUP ID',
    NO_FILTER: '<Select filter>',
  },

  Actors: {
    OPERATOR: 'Operator',
  },

  MenuItems: {
    'SANCTION LISTS': 'Sanction lists',
    'DATA FEEDS': 'Data feeds',
    'MACHINE INTELLIGENCE': 'Machine intelligence',
    'TENANT CONFIGURATION': 'Workflows & users',
    'RULE MANAGER': 'Rule manager',
    'AUDIT TRAIL': 'Audit trail',
    'EFFICIENCY DASHBOARD': 'Efficiency dashboard',
    CASE_STATISTIC_DASHBOARD: 'Case Statistics & Health',
    'DETACHMENT CONFIGURATION': 'Detachment configuration',
    TOOLING: 'Tooling',
    'TENANT MANAGER': 'Tenant manager',
    CUSTOMER_RISK_RATING: 'Customer Risk Rating',
    SETTINGS: 'Settings',
  },

  AuditTrackColumns: {
    NAME: 'NAME',
    TYPE: 'TYPE',
    TIMESTAMP: 'TIMESTAMP',
    TEXT: 'TEXT',
  },

  NoteColumns: {
    DATE: 'DATE',
    USER: 'USER',
    TEXT: 'TEXT',
    TYPE: 'TYPE',
  },

  SanctionListColumns: {
    TYPE: 'TYPE',
    NAME: 'NAME',
    STATUS: 'STATUS',
  },

  SanctionFilterTitles: {
    'FILTER BY': 'FILTER BY',
    'FILTER VALUE': 'FILTER VALUE',
    STATUS: 'STATUS',
  },

  PageTitles: {
    'AUDIT TRAIL': 'Audit trail',
    'CASE DETAILS': 'Current case ',
    'SANCTION LISTS': 'Sanction lists',
    'CTR REPORT': 'CTR Report',
    'SAR REPORT': 'SAR Report',
    TENANT: 'Tenant:',
  },

  // DEPRECATED
  // use instead mapping:patternType
  SanctionHitTypes: {
    BP: 'Blacklisted Person',
    BLACKLIST: 'Blacklist',
    BE: 'Blacklisted Entity',
    PEP: 'Politically Exposed Person',
    SIP: 'Special Interest Person',
    RCA: 'Relative or Close Associate',
    SIE: 'Special Interest Entity',
    SP: 'Sanctioned Person',
    SE: 'Sanctioned Entity',
    COUNTRY_RISK_HIGH: 'Country Risk High',
    COUNTRY_RISK_MEDIUM: 'Country Risk Medium',
    COUNTRY_RISK_V2: 'Country Risk',
    VOLUME_RISK: 'Volume Risk',
    VOLUME_RISK_V2: 'Volume Risk',
    USAGE_RISK: 'Risky Words',
    MONEY_MULE: 'Money Mule',
    PHISHING: 'Phishing',
    FAN_IN: 'Fan In',
    REFERENCE_ACCOUNT: 'Reference Account Mismatch',
    ACCOUNT_BLACKLISTED: 'Account Blacklisted',
    CASH_TRANSACTION: 'Cash Volume Risk',
    PASSTHROUGH: 'Passthrough Account Risk',
    PASSTHROUGH_V2: 'Passthrough Bi-directional Account Risk',
    PASSTHROUGH_V3: 'Passthrough Multiple In/Out',
    RISKY_WORDS_V2: 'Risky Words',
    SMURFING: 'Smurfing Risk',
    ANOMALY: 'Behavioral Analysis Anomaly',
    ROUND_AMOUNT: 'Round Amount',
    UNUSUAL_COUNTER_PARTY_FREQUENCY: 'Unusual Counterparty Frequency',
    ACCOUNT_BASED_FREQUENCY: 'Account Based Frequency',
    DORMANT_ACCOUNT_ACTIVATION: 'Dormant Account Activation',
    CROSS_BORDER_VOLUME: 'Account-based cross-border volume',
    VOLUME_INCREASE_DECREASE: 'Account-based volume increase/decrease',
    SUSPICIOUS_AVG_TICKET_SIZE: 'Account-based suspicious average ticket size increase/decrease',
    ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE: 'Account-based frequency increase/decrease',
    ROUND_AMOUNT_RATIO_INCREASE_DECREASE: 'Round amount ratio increase/decrease',
    COUNTER_PARTY_VOLUME: 'Counterparty Volume',
    REG_314A: '314a list',
    AMP: 'Adverse Media',
    AME: 'Adverse Media',
    SOE: 'State Owned Enterprises',
    PLAUSIBILITY: 'Field Length check',
    REFERENCE_ACCOUNT_V2: 'Reference account v2',
    AE: 'Associated Entity',
    AP: 'Associated Person',
    EXPECTED_CUSTOMER_VALUE: 'Expected Customer Value',
    PERCENTAGE_RULE: 'Percentage Rule',
    GROUPING_RULE: 'Grouping Rule',
    VOLUME_RISK_V3: 'Volume Risk V3',
    FREQUENCY_V2: 'Frequency V2',
  },

  HitStatus: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
  },

  OccupationTypes: {
    PRIMARY_OCCUPATION: 'Primary occupation',
    PREVIOUS_ROLES: 'Previous roles',
    OTHER_ROLES: 'Other roles',
  },

  CountryRisk: {
    HIGH: 'high risk',
    MEDIUM: 'medium risk',
    LOW: 'low risk',
  },

  SanctionHitLabels: {
    NA: 'N/A',
    GEOGRAPHY: 'Geography',
    FUNDS_BENEFICIARY: 'Funds beneficiary',
    FUNDS_ORIGINATOR: 'Funds originator',
    NATIONALITY: 'Nationality',
    RESIDENT_OF: 'Resident of',
    JURISDICTION: 'Jurisdiction',
    IBAN_COUNTRY: 'IBAN Country',
    IDENTIFICATION: 'Identification',
    DATE_OF_BIRTH: 'Date of Birth',
    NATIONAL_ID: 'National ID',
    GENDER: 'Gender',
    COUNTRY_PHONE: 'Phone number countries',
    CITY: 'City',
    COUNTRY: 'Country',
    ADDRESS: 'Address',
    LINE: 'Line',
    COUNTRY_OF_BIRTH: 'Country of Birth',
    PLACE_OF_BIRTH: 'Place of Birth',
    COUNTRY_OF_REPORTED_ALLEGATION: 'Country of Reported Allegation',
    COUNTRY_OF_REGISTRATION: 'Country of Registration',
  },

  CustomerPersonDetailLabels: {
    GENERAL_INFO: 'General info',
    NAME: 'Name',
    GENDER: 'Gender',
    BIRTHDATE: 'Birthdate',
    PLACE_OF_BIRTH: 'Place of birth',
    CONTACT_INFO: 'Contact info',
    EMAIL: 'Email',
    PHONE: 'Phone',
    ADDRESS: 'Address',
    CONTACT_NAME: 'Name',
    CONTACT_NAME_EXTENDED: 'Name extended',
    STREET: 'Street',
    STREET_EXTENDED: 'Street extended',
    ZIP: 'Zip',
    TOWN: 'Town',
    REGION: 'Region',
    COUNTRY: 'Country',
    INDENTITY_INFO: 'Indentity info',
    NATIONALITY: 'Nationality',
    DOCUMENTS: 'Documents',
    CUSTOMER_INFO: 'Customer info',
    CUSTOMER_ID: 'Customer ID',
    CUSTOMER_GROUP_ID: 'Customer group ID',
    ACQUISITION_DATE: 'Acquisition date',
    CUSTOMER_SINCE: 'Customer since',
    RISK_CATEGORY: 'Risk category',
    HIGH: 'high',
    LOW: 'low',
    MEDIUM: 'medium',
    characteristics: {
      heading: 'Characteristics',
      depositDelay: {
        heading: 'Deposit Delay',
      },
      discountFrequency: {
        heading: 'Discount Frequency',
      },
      expectedIncomeYearly: {
        heading: 'Expected Income Yearly',
      },
      expectedVolume: {
        daily: {
          heading: 'Expected Volume Daily',
        },
        monthly: {
          heading: 'Expected Volume Monthly',
        },
      },
      expectedVolumeVolatility: {
        heading: 'Expected Volume Volatility',
      },
    },
  },

  ConfigurationManagementLabels: {
    RULE_EFFECTIVENESS_OVERVIEW: 'Rule Effectiveness Overview',
    AUTO_CLOSE_AUTOMATION_PER_CATEGORY: 'Auto close Automation per Category',
    HITS_PER_CATEGORY_OVERTIME: 'Hits per Category over time',

    SANCTION_AND_PEP_RULES: 'Sanction and PEP Rules',
    NAMES_HANDLING_STEPS: 'Name Handling Steps',

    COUNTRY_RULES: 'Country Rules',
    SETTINGS_COUNTRY_RULES: 'Settings - Country Rules',
    HIGH_RISK_COUNTRIES: 'High Risk Countries',
    TRANSACTION_WILL_GET_BLOCKED: 'Transaction will get Blocked',
    LOW_RISK_COUNTRIES: 'Low Risk Countries',
    MEDIUM_RISK_COUNTRIES: 'Medium Risk Countries',
    MEDIUM_RISK_MESSAGE: 'All Countries other than High & Low Risk',

    VOLUME_RULES: 'Volume Rules',
    TIMEFRAME_VOLUME_AGGREGATION: 'Timeframe Volume Aggregation',
    AGGREGATED_CUSTOMER_TRANSFER_THRESHOLD: 'Aggregated Customer Transfer Thresholds',
    LOW_RISK: 'Low Risk Customer: ',
    MEDIUM_RISK: 'Medium Risk Customer: ',
    HIGH_RISK: 'High Risk Customer: ',
    ALLOWED_AMOUNT_DEVIATION_OF_MEAN_IN_PERCENT: 'Allowed Amount Deviation of Mean (in Percent)',
    ALLOWED_AMOUNT_DEVIATION_OF_MEAN: 'Allowed Amount Deviation of Mean: ',
    THRESHOLD_ACCELERATOR_FACTOR: 'Threshold Accelerator Factor: ',
    VOLUME_RULES_NOTE:
      'Threshold accelerator factor means if the volume risk is accepted by an operator the system will automatically close cases until the aggregated amount has reached the factor times the original threshold and amount stays within deviation range.',
    RESULTING_ACTIVE_CONFIGURATION: 'Resulting active configuration:',

    OTHER_RULES: 'Other Rules',
    SETTINGS_OTHER_RULES: 'Settings - Other Rules',
    RISKY_WORDS: 'Risky Words',
    MACHINE_LEARNING: 'Machine Learning',
    COUNTRY_RISK: 'Country Risk',
    VOLUME_RISK: 'Volume Risk',
    PEP_SANCTION: 'PEP Sanction',

    RISKY_WORDS_RULES: 'Risky Words Rules',
    SETTINGS_RISKY_WORDS_RULES: 'Settings - Risky Words Rules',

    PASSTHROUGH_RULES: 'Passthrough Rules',
    SETTINGS_PASSTHROUGH_RULES: 'Settings - Passthrough Rules',

    SMURFING_RULES: 'Smurfing Rules',
    SETTINGS_SMURFING_RULES: 'Settings - Smurfing Rules',

    MACHINE_LEARNING_RULES: 'Bank False Positive Filter',

    BEHAVIOURAL_ANALYSIS: 'Behavioural Analysis',

    ACCOUNT_BLACKLIST_CONFIGURATION: 'Account Blacklist Configuration',
    CASH_VOLUME_RISK_CONFIGURATION: 'Cash Volume Risk Configuration',

    PATTERN_DETECTION: 'Cross-Bank Pattern Detection',
    PATTERN_REPOSITORY: 'Pattern Repository',
    MONEY_MULE: 'Money Mule',
    PHISHING: 'Phishing',
    FAN_IN: 'Fan In',
    SHELL_COMPANY: 'Shell Company',
    SEND_MONEY_HOME: 'Send Money Home To Family',
    REFERENCE_ACCOUNT: 'Reference Account Mismatch',

    ACTIVATED_ACCOUNT_BLACKLIST_FILE: 'Activated Account Blacklist File',
    ACTIVATED_RISKY_WORDS_FILE: 'Activated Risky Words File',
    SEARCH_FOR_RISKY_WORDS_IN: 'Search for risky words in',

    GENERAL: 'General',
    TRANSFER_BASED: 'Transfer-based',
    ACCOUNT_BASED: 'Account-based',
    CUSTOMER_BASED: 'Customer-based',
    CUSTOMER_GROUP_BASED: 'Customer group-based',

    NO_RULE_APPLIED: '<br/><b>No rules apply.<b>',
    TIME_FRAME_ALERT_MSG: 'This setting already exists.',
    TRANSFER_ALERT_MSG: 'There are some rules in Transfer-based with threshold <= 0.',
    ACCOUNT_ALERT_MSG: 'There are some rules in Account-based with threshold <= 0.',
    CUSTOMER_ALERT_MSG: 'There are some rules in Customer-based with threshold <= 0.',
    CUSTOMER_GROUP_ALERT_MSG: 'There are some rules in Customer group-based with threshold <= 0.',
    TRANSFER_TIME_FRAME_ALERT_NULL_MSG:
      'There are some rules in Transfer-based with timeframe is empty.',
    TRANSFER_TRANSACTION_TYPE_ALERT_NULL_MSG:
      'There are some rules in Transfer-based with transaction type is empty.',
    ACCOUNT_TIME_FRAME_ALERT_NULL_MSG:
      'There are some rules in Account-based with timeframe is empty.',
    ACCOUNT_TRANSACTION_TYPE_ALERT_NULL_MSG:
      'There are some rules in Account-based with transaction type is empty.',
    CUSTOMER_TIME_FRAME_ALERT_NULL_MSG:
      'There are some rules in Customer-based with timeframe is empty.',
    CUSTOMER_TRANSACTION_TYPE_ALERT_NULL_MSG:
      'There are some rules in Customer-based with transaction type is empty.',
    CUSTOMER_GROUP_TIME_FRAME_ALERT_NULL_MSG:
      'There are some rules in Customer group-based with timeframe is empty.',
    CUSTOMER_GROUP_TRANSACTION_TYPE_ALERT_NULL_MSG:
      'There are some rules in Customer group-based with transaction type is empty.',
    NO_RULE_TEXT: 'No rules apply.',
    'ADD TRANSFER-BASED RULE': 'ADD TRANSFER-BASED RULE',
    'ADD ACCOUNT-BASED RULE': 'ADD ACCOUNT-BASED RULE',
    'ADD CUSTOMER-BASED RULE': 'ADD CUSTOMER-BASED RULE',
    'ADD CUSTOMER GROUP-BASED RULE': 'ADD CUSTOMER GROUP-BASED RULE',
    'TRANSFER-BASED VOLUME RULES': 'Transfer-based volume rules',
    'ACCOUNT-BASED VOLUME RULES': 'Account-based volume rules',
    'CUSTOMER-BASED VOLUME RULES': 'Customer-based volume rules',
    'CUSTOMER GROUP-BASED VOLUME RULES': 'Customer group-based volume rules',
    'ATTACHED VOLUME RULES': 'ATTACHED VOLUME RULES',
    'ATTACH VOLUME RULE': 'ATTACH VOLUME RULE',
    'HIGH RISK COUNTRY SETTINGS': 'High Risk Country Settings',
    'MEDIUM RISK COUNTRY SETTINGS': 'Medium Risk Country Settings',
    'LOW RISK COUNTRY SETTINGS': 'Low Risk Country Settings',
  },

  SanctionPepRules: {
    onlyUseCurrentListsAndIgnoreSuspended: 'Only use Current lists and ignore Suspended',
    checkForPep: 'Check for Politically Exposed Person (PEP)/Relative or Close Associate (RCA)',
    checkForSipSie: 'Check for Special Interest Person (SIP)/Special Interest Entities (SIE)',
    checkForSoe: 'Check for State Owned Enterprises (SOE)',
    checkForAssociatedEntity:
      'Check for Associated Entity/Associated Person (from chosen Sanction Lists)',
    immediateDecision: 'Immediately response with PEP/sanction decision',
  },
  BlacklistRules: {
    immediateDecision: 'Immediately response',
  },

  Common: {
    APP_TITLE: 'AML Case Manager',
    FORGOT_PWD_TITLE: "Don't remember your password?",
    LOGIN_BUTTON: 'Login',
    SEND_EMAIL_BUTTON: 'Send Email',
    SEND_EMAIL_TITLE:
      'Please enter your email address. We will send you an email to reset your password.',
    EMAIL_SENDER: 'no-reply@auth0user.net',
    FROM: ' from',
    ACTIVATED: 'Activated',
    DEACTIVATED: 'Deactivated',
    SAVE: 'Save',
    NO_CHANGES: 'No Changes',
    BY: 'by',
    UPLOAD_RISKY_WORDS_FILE: 'Upload Risky Words File',
    DOWNLOAD_RISKY_WORDS_FILE: 'Activated Risky Words and History',
    DOWNLOAD_RISKY_WORDS_HINT: 'Click on the link to download and edit',
    CHOOSE_YOUR_CSV_FILE: 'Choose your CSV file',
    RISKY_WORDS: 'Risky Words',
    SUSPICIOUS_WORDS_UPDATED: 'Suspicious Words have new updates.',
    UPLOAD_ERROR: 'Upload Error!',

    ACCOUNT_BLACKLIST: 'Account Blacklist',
    UPLOAD_ACCOUNT_BLACKLIST_FILE: 'Upload Account Blacklist File',
    DOWNLOAD_ACCOUNT_BLACKLIST_FILE: 'Activated Account Blacklist and History',
    DOWNLOAD_ACCOUNT_BLACKLIST_HINT: 'Click on the link to download and edit',
    FILE_SELECT_ERROR: 'Please select a CSV file to upload!',
    ERROR_TITLE: 'Error',
    ASSIGN_TO_OPERATOR_TITLE: 'Assign to another operator',
    FILTER_BUTTON: 'Filter',
    ALL: 'All',
    CREDIT: 'Credit',
    DEBIT: 'Debit',
    TRANSACTION_HISTORY_OF: 'Transaction history of',
    PROFILE_OF: 'Profile of',
    'CREDIT/DEBIT': 'Credit / Debit',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    OPEN_CASE_TOOLTIP: 'Open case details',
    PREVIOUS_ALERTS: 'Previous or open alerts for customer',
    ACCOUNT_NUMBER: 'Account number',
    TRANSACTIONS_HISTORY_OF_CUSTOMER: 'Transactions history of customer',
    ACTIVE: 'Active',
    SETTING: 'Setting',
    TIMEFRAME: 'Timeframe',
    TRANSACTION_TYPE: 'Transaction type',
    LOW_RISK_CUSTOMER: 'Low risk customer',
    MEDIUM_RISK_CUSTOMER: 'Medium risk customer',
    HIGH_RISK_CUSTOMER: 'High risk customer',
    ACCOUNT_ID: 'Account ID',
    BANK_IDENTIFIER: 'Bank identifier',
    LAST_4: 'Last 4 digits',
    ACCOUNT_EMAIL_ID: 'Account email ID',
    BANK_COUNTRY: 'Bank country',
    SCHEME: 'Scheme',
    PAYMENT_CHANNEL: 'Channel',
    PAYMENT_MEANS: 'Payment means',
    PAYMENT_TYPE: 'Payment type',
    CURRENT_TRANSACTION: 'Current transaction',
    TRANSACTION_RESULT: 'Transaction result',
    OPERATOR: 'Operator',
    AUDIT_NOTE: 'Audit Note',
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
    REMOVE_ITEM_TITLE: 'Remove Setting Item',
    REMOVE_ITEM_QUESTION: 'Are you sure to remove this Setting?',
    NEW_SETTING_ITEM: 'New Setting Item',
    REMOVE_GROUP_TITLE: 'Remove Setting Group',
    REMOVE_GROUP_QUESTION: 'Do you want to delete all rules in this group?',
    REMOVE_GROUP_TOOLTIP: 'Remove all rules in this group',
    NO_RULES_MESSAGE: 'There is no configuration at the moment',
    SPLIT_OUT_CASE: 'Split case from case group',
    CONFIGURATION_TYPE: 'Configuration type',
    PRODUCT_TYPE: 'Account product type (Counterparty)',
    ORGANIZATION_TYPE: 'Organization type',
    CUSTOMER_RISK_LEVEL: 'Customer risk level',
    COUNTRY_RISK_LEVEL: 'Counterparty country risk level',
    CUSTOMER_TYPE: 'Customer category',
    TITLE: 'Title',
    AMOUNT_THRESHOLD: 'Amount threshold',
    NOTES: 'Notes',
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
    PROHIBITED: 'Critical',
    GENERAL_FILTERS: 'General filters',
    ANY: 'any',
    PRODUCT_TYPE_TITLE: 'Product type',
  },

  DecisiveDataFeatures: {
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_HIGH]:
      "Bank's risk assessment for this customer",
    [DataFeatureName.VOLUME_RISK_ONE_MONTH_AGGREGATED_TX_COUNT]: 'Transaction count for one month',
    [DataFeatureName.VOLUME_RISK_ONE_MONTH_VOLUME_ACCELERATOR_THRESHOLD_MULTIPLIER]:
      'Accelerating multiplyer for the default one month volume threshold',
    [DataFeatureName.VOLUME_RISK_ONE_MONTH_AMOUNT_AVERAGE_DISTANCE_PERCENTAGE_THRESHOLD]:
      'Threshold for distance from one month average',
    [DataFeatureName.VOLUME_RISK_ONE_MONTH_AMOUNT_AVERAGE_DISTANCE_PERCENTAGE_VALUE]:
      'Distance from one month average',
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_NATIONALITY_RISK_MEDIUM]:
      "Account holding party's nationality is rated as medium risk",
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_NATIONALITY_RISK_HIGH]:
      "Account holding party's nationality is rated as high risk",
    [DataFeatureName.VOLUME_RISK_ONE_MONTH_SETTLED_FUNDS_AGGREGATED_AMOUNT]:
      'Aggregated amounts of one month',
    [DataFeatureName.COMMON_SETTLED_FUNDS_AMOUNT]: 'Settled funds',
    [DataFeatureName.SIMILARITY_HOLDER_NAME_ORIGINATOR_BENEFICIARY]:
      'Similarity between the name of the originator and the name of the holder',
    [DataFeatureName.TRANSACTION_INITIATING_PARTY_FUNDS_ORIGINATOR]:
      "The transaction was initiated by the fund's originator",
    [DataFeatureName.VOLUME_RISK_ONE_MONTH_VELOCITY_ACCELERATOR_THRESHOLD_MULTIPLIER]:
      'Accelerating multiplyer for the default one month velocity threshold',
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_COUNTRY_OF_BIRTH_EQUALS_COUNTER_PARTY_COUNTRY_OF_IBAN]:
      'The country of birth of the account holder and country indicated by the iban of the counterparty are the same',
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_SUM_INCOMING_SETTLED_FUNDS_LAST_MONTH_VS_SETTLED_FUNDS]:
      'Transfer amount plausible compared to regular customer income', // ToDo: feature name seems wrong
    [DataFeatureName.PEPSANCTION_RISK_DECISION_CASE_STATUS]: 'Pepsanction risk decision',
    [DataFeatureName.FUNDS_PASSED_THROUGH_QUICKLY]:
      'Fast movement of money in/out from/to unusual source/destination',
    [DataFeatureName.VOLUME_RISK_DECISION_CASE_STATUS]: 'Volume risk decision',
    [DataFeatureName.COUNTRY_RISK_DECISION_CASE_STATUS]: 'Country risk decision',
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_LOW]:
      "Bank's risk assessment for this customer is low",
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_MEDIUM]:
      "Bank's risk assessment for this customer is medium",
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_FUNDS_BENEFICIARY]:
      'Account holding party is funds beneficiary',
    [DataFeatureName.ACCOUNT_HOLDING_PARTY_FUNDS_ORIGINATOR]:
      'Account holding party is funds originator',
    [DataFeatureName.ORIGINATOR_ACCOUNT_NUMBER_COUNTRY_EQUALS_REFERENCE_ACCOUNT_NUMBER_COUNTRY]:
      'Country of originator account and country of the reference account are the same',
    [DataFeatureName.TRANSACTION_EXPECTED_BASED_ON_TIME_AND_AMOUNT]:
      'Timing and amount of transaction matches expected pattern',
    [DataFeatureName.ORIGINATOR_ACCOUNT_HOLDER_NAME_EQUALS_REFERENCE_ACCOUNT_HOLDER_NAME]:
      'Name of originator and name of the reference account holder are the same',
    'account-holding-party-accumulation-time-for-money-spent-in-days':
      'Accumulation time for equivalent credit volume compared to volume debited in days',
    'count-of-accounts-with-income-from-same-originator-and-spent-to-same-beneficiary':
      'Count of accounts with credit from same originator and debit to same beneficiary',
    'count-of-accounts-with-income-from-same-originator-and-spent-to-same-beneficiary-divided-by-count-of-accounts-spent-to-same-beneficiary':
      'Ratio between count of accounts with credit from same originator and debit to same beneficiary vs count of accounts debit to same beneficiary only',
    'ratio-one-rolling-month-volume-vs-all-time-monthly-average-volume':
      'Ratio between one rolling month volume vs all time monthly average volume',
    'rolling-month-income': 'High rolling month credit volume',
    [DataFeatureName.REFERENCE_ACCOUNT]: 'Legitimate Payment from unexpected account',
  },

  DecisionName: {
    [DecisionName.PEPSANCTION_DECISION]: 'Sanction/PEP Hit',
    [DecisionName.COUNTRY_RISK_DECISION]: 'Country Risk',
    [DecisionName.COUNTRY_RISK_V2_DECISION]: 'Country Risk',
    [DecisionName.VOLUME_RISK_DECISION]: 'Volume Risk',
    [DecisionName.VOLUME_RISK_V2_DECISION]: 'Volume Risk',
    [DecisionName.USAGE_RISK_DECISION]: 'Risky Words',
    [DecisionName.AI_DECISION]: 'Machine Intelligence',
    [DecisionName.ACCOUNT_BLACKLISTED_DECISION]: 'Account Blacklisted',
    [DecisionName.MONEY_MULE_DECISION]: 'Money Mule',
    [DecisionName.PHISHING_DECISION]: 'Phishing',
    [DecisionName.FAN_IN_DECISION]: 'Fan In',
    [DecisionName.CASH_TRANSACTION_DECISION]: 'Cash Volume Risk',
    [DecisionName.PASSTHROUGH_DECISION]: 'Passthrough Account Risk',
    [DecisionName.ANOMALY_DECISION]: 'Behavioural Analysis',
    [DecisionName.SMURFING_DECISION]: 'Smurfing Risk',
    [DecisionName.ROUND_AMOUNT_DECISION]: 'Round Amount',
    [DecisionName.UNUSUAL_COUNTER_PARTY_FREQUENCY_DECISION]: 'Counterparty Frequency',
    [DecisionName.ACCOUNT_BASED_FREQUENCY_DECISION]: 'Account Based Frequency',
    [DecisionName.DORMANT_ACCOUNT_ACTIVATION_DECISION]: 'Dormant Account Activation',
    [DecisionName.CROSS_BORDER_VOLUME_DECISION]:
      'Account-based cross-border volume increase/decrease',
    [DecisionName.VOLUME_INCREASE_DECREASE_DECISION]: 'Account-based volume increase/decrease',
    [DecisionName.SUSPICIOUS_AVG_TICKET_SIZE_DECISION]:
      'Account-based suspicious average ticket size increase/decrease',
    [DecisionName.ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_DECISION]:
      'Unusual account based frequency increase/decrease',
    [DecisionName.ROUND_AMOUNT_RATIO_INCREASE_DECREASE_DECISION]:
      'Round amount ratio increase/decrease',
  },

  AutoClosedBox: {
    CONTEXTUAL_REPLAY_TITLE: 'Auto-Closed because:',
    COMPLEX_TITLE:
      'The case was auto-closed with a certainty of {{confidencePercent}} despite identified risk: {{caseTypesList}} ',
    REFERENCE_ACCOUNT_TITLE:
      'The case was auto-closed because of pattern "Legitimate payment from unexpected account" with a certainty of {{confidencePercent}} despite identified risk: "Reference Account Mismatch" ',
    SEND_MONEY_HOME_TITLE:
      'The case was auto-closed because of pattern "Send Money Home to Family" with a certainty of {{confidencePercent}} despite identified risk: "Country Risk" ',
    FOLLOWING_FEATURE_IMPORTANCE: 'based on the following feature importance:',
  },
  NotSubstantiatedBox: {
    CONTEXTUAL_REPLAY_TITLE: 'Not-substantiated because:',
    COMPLEX_TITLE:
      'The case was not-substantiated with a certainty of {{confidencePercent}} despite identified risk: {{caseTypesList}} ',
    REFERENCE_ACCOUNT_TITLE:
      'The case was not-substantiated because of pattern "Legitimate payment from unexpected account" with a certainty of {{confidencePercent}} despite identified risk: "Reference Account Mismatch" ',
    SEND_MONEY_HOME_TITLE:
      'The case was not-substantiated because of pattern "Send Money Home to Family" with a certainty of %d despite identified risk: "Country Risk" ',
    FOLLOWING_FEATURE_IMPORTANCE: 'based on the following feature importance:',
  },

  LabelBox: {
    TITLE: 'The transaction was identified as',
    'CONFIDENCE-MESSAGE':
      'The confidence level identifying the pattern is %d based on the following feature importance:',
    'SEND MONEY HOME TO FAMILY':
      '"Send money home to family" based on the following feature importance:',
    'LEGITIMATE PAYMENT FROM UNEXPECTED ACCOUNT':
      '"Legitimate payment from unexpected account" based on the following feature importance:',
  },

  Units: {
    'time-days': 'Days',
    ratio: 'Ratio',
    quantity: 'Quantity',
    'currency-eur': 'EUR',
    score: 'Score',
    binary: 'Binary',
    gender: 'Gender',
  },

  AssessmentAnswer: {
    [AssessmentAnswer.NO_MATCH]: 'NO MATCH',
    [AssessmentAnswer.MATCH]: 'MATCH',
    [AssessmentAnswer.INCONCLUSIVE]: 'INCONCLUSIVE',
  },

  StateChangeAction: {
    ASSIGN_TO_ME: 'assigned the case to herself/himself',
    ASSIGN_TO_OPERATOR: 'assigned the case to an operator',
    ASSIGN_TO_ANOTHER_OPERATOR: 'assigned the case to another operator',
    RETURN_TO_OPERATOR: 'returned the case to operator',
    ACCEPT: 'closed the case',
    ESCALATE: 'escalated the case',
    FILE_SAR: 'filed SAR',
    SUBMIT_SAR: 'submitted SAR',
    REOPEN: 'reopened the case',
    UPDATE_CASE_STATUS_FORCEFULLY: 'updated case status forcefully',
    OPEN: 'opened the case',
  },

  VolumeRules: {
    CURRENT_DAY: 'Current day',
    ONE_WEEK_ROLLING: '1 Week rolling',
    TWO_WEEKS_ROLLING: '2 Weeks rolling',
    THREE_WEEKS_ROLLING: '3 Weeks rolling',
    ONE_MONTH_ROLLING: '1 Month rolling',
    TWO_MONTHS_ROLLING: '2 Months rolling',
    THREE_MONTHS_ROLLING: '3 Months rolling',

    CREDIT_ONLY: 'Credit only',
    DEBIT_ONLY: 'Debit only',

    LOW_RISK: 'low risk',
    MEDIUM_RISK: 'medium risk',
    HIGH_RISK: 'high risk',
  },

  TransactionType: {
    [TransactionType.ANY]: 'Any',
    [TransactionType.DEBIT]: 'Debit only',
    [TransactionType.CREDIT]: 'Credit only',
  },

  TransactionTypeText: {
    [TransactionType.ANY]: 'credit and debit',
    [TransactionType.DEBIT]: 'debit only',
    [TransactionType.CREDIT]: 'credit only',
  },

  CrossBorderComputeSegment: {
    [ComputeSegment.HOUR]: 'Hour',
    [ComputeSegment.DAY]: 'Day',
  },

  CrossBorderComputeSegmentAdv: {
    [ComputeSegment.DAY]: 'daily',
    [ComputeSegment.WEEK]: 'weekly',
    [ComputeSegment.MONTH]: 'monthly',
  },

  VolumeIncreaseDecreaseComputeSegment: {
    [ComputeSegment.HOUR]: 'Hour',
    [ComputeSegment.DAY]: 'Day',
  },

  VolumeIncreaseDecreaseComputeSegmentAdv: {
    [ComputeSegment.DAY]: 'daily',
    [ComputeSegment.WEEK]: 'weekly',
    [ComputeSegment.MONTH]: 'monthly',
  },

  SuspiciousAvgTicketSizeComputeSegment: {
    [ComputeSegment.HOUR]: 'Hour',
    [ComputeSegment.DAY]: 'Day',
  },

  SuspiciousAvgTicketSizeComputeSegmentAdv: {
    [ComputeSegment.HOUR]: 'hour',
    [ComputeSegment.DAY]: 'day',
  },

  DefaultRuleLabel: 'Untitled',

  CrossBorderVolumeLabels: {
    descriptionId: '1441857557',
    id: 17,
    title: 'Account-based cross-border volume increase/decrease',
    icon: crossBorderVolumeIcon,
    tabPrefix: 'Cross-border volume',
    activeField: 'crossBorderVolumeActive',
    caseIdKey: 'related-transactions',
    tabId: '_tabCrossBorderVolume',

    // config labels
    recallPeriod: 'Total consideration period ',
    multiplier: 'Volume increase/decrease (in percent)',
    computeSegment: 'Time segment for comparison',
    transactionType: 'Transaction type',
    minimumTransactions: 'Minimum number of transactions for being applicable',
  },

  VolumeIncreaseDecreaseLabels: {
    descriptionId: '1524563969',
    id: 18,
    title: 'Account-based volume increase/decrease',
    icon: volumeIncreaseDecreaseIcon,
    tabPrefix: 'Volume increase decrease',
    activeField: 'volumeIncreaseDecreaseActive',
    caseIdKey: 'related-transactions',
    tabId: '_tabVolumeIncreaseDecrease',

    // config labels
    recallPeriod: 'Total consideration period',
    multiplier: 'Volume increase/decrease (in percent)',
    computeSegment: 'Time segment for comparison',
    transactionType: 'Transaction type',
    minimumTransactions: 'Minimum number of transactions for being applicable',
  },

  SuspiciousAvgTicketSizeLabels: {
    descriptionId: '1639809025',
    id: 19,
    title: 'Account-based suspicious average ticket size increase/decrease',
    suspectedText: 'account-based suspicious average ticket size increase/decrease',
    icon: suspiciousAvgTicketSizeIcon,
    tabPrefix: 'Suspicious average ticket size',
    activeField: 'suspiciousAvgTicketSizeActive',
    caseIdKey: 'related-transactions',
    tabId: '_tabSuspiciousAvgTicketSize',

    // config labels
    shortTermPeriod: 'Short-term period ',
    longTermPeriod: 'Long-term period ',
    multiplier: 'Ticket size increase/decrease (in percent)',
    computeSegment: 'Time segment for comparison',
    transactionType: 'Transaction type',
    minimumTransactions: 'Minimum number of transactions for being applicable',
  },

  RiskLevel: {
    any: 'Any',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    prohibited: 'Critical',
  },
};

export default Strings;
